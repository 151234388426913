require('./inlineplayer.js');
require('./leaflet/leaflet-src.js');
require('./leaflet/rastercoords.js');
const {createArtwork} = require('./artwork.js');
const {stripeHandler} = require('./stripe.js');

window.Artwork = createArtwork(L, jQuery);

jQuery(function($) {

    var $body, $title, $bigtitle, timeout,
        states = ['player-stopped', 'player-playing', 'player-paused'],
        currentState = states[0],
        versionString = window.currentVersion.toString();

    if (sessionStorage.getItem("version") != versionString) {
        $("#splash").show();
    }
    sessionStorage.setItem("version", versionString); 

    //Form data handler
    stripeHandler($);

    //Cheating with height calculations
    var height;
    var $anchor = $("#download_info");
    var $back = $("#back");
    var $front = $("#front");
    var recalcHeights = function() {
        var tmp = $(window).innerHeight();
        if (height != tmp) {
            var offset = $anchor.offset().top - $back.offset().top + 36; //magic number to adjust for nav height
            $front.css({
                height: tmp,
                top: (tmp + offset) * -1 
            });
            height = tmp;
        }
    };
    recalcHeights();
    $(window).on("resize orientationchange", recalcHeights);

    var artwork = new Artwork();
    artwork.setVersion(currentVersion);
    window.aw = artwork;

    //Player-specific stuff
    $body = $('body').addClass(currentState);
    $title = $('#player_title');
    $bigtitle = $('#front .bigtitle');

    var resetTicker = function() {
        $title.removeClass('run');
        void $title[0].offsetWidth; //trigger reflow
        $title.addClass('run');
    };

    $("#player .play").on('click', function() {

        inlinePlayer.play();
    });
    $("#player .pause").on('click', function() {
        inlinePlayer.pause();
    });

    $(document).on('player:playing', function(e, o, progress, pos) {
    });

    var randPoint, previousSong = null;

    $(document).on('player:play', function(e, link, num) {
        var mover;

        $title.html("#" + num + " " + link.textContent);
        $bigtitle.html("#" + num + " " + link.textContent).stop().fadeIn(500);

        artwork.setColorByIndex(num - 1);

        clearTimeout(timeout);
        timeout = setTimeout(function() {
            $bigtitle.fadeOut(1500);
        }, 5000);
        resetTicker();

        //New point on 
        if (num !== previousSong) {
            randPoint = artwork.randomPoint();
            previousSong = num;
        }
        mover = function() {
            var p = artwork.randomPoint();
            artwork.moveTo(p.x, p.y, mover);
        };
        artwork.moveTo(randPoint.x, randPoint.y, mover);



    });
    $(document).on('player:stateChange', function(e, state) {
        if (state == 1) {
            resetTicker();
            clearTimeout(timeout);
            $bigtitle.fadeIn(200);
        } else {
            artwork.stop();
            clearTimeout(timeout);
            $bigtitle.fadeOut(200);
        }
        $body.removeClass(currentState);
        currentState = states[state];
        $body.addClass(currentState);
    });

    //Control player with keyboard
    $("body").on('keydown', function(e) {
        switch (e.which) {
            case 32: //Space
                if (currentState == 'player-playing') {
                    inlinePlayer.pause();
                } else {
                    inlinePlayer.play();
                }
                e.preventDefault();
                break;
            case 37: //left
                inlinePlayer.prev();
                break;
            case 39: //right
                inlinePlayer.next();
                break;

        }
    });

    //Select text on focus
    $("input").focus(function() { $(this).select(); } );


    //Scroll fixer
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 800, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });



});
