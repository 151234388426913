const vh = require('../../lib/version-handler');

module.exports.createArtwork = function(L, $) {
    const zoomLevel = 11,
        minZoom = 11,
        maxZoom = 11,
        imgDimensions = [
            200000, // original width of image, don't change
            400000  // original height of image, don't change
        ],
        imgOffset = [4000, 200000-4000],
        sidePixels = 3000,
        versionPadSize = ("" + vh.MAXVERSION);
    
    var rc;

    L.BackgroundTileLayer = L.TileLayer.extend({

        // @method createTile(coords: Object, done?: Function): HTMLElement
        // Called only internally, overrides GridLayer's [`createTile()`](#gridlayer-createtile)
        // to return an `<img>` HTML element with the appropiate image URL given `coords`. The `done`
        // callback is called when the tile has been loaded.
        createTile: function (coords, done) {
            var img = document.createElement('img');
            var tile = document.createElement('div');

            L.DomEvent.on(img, 'load', L.bind(this._tileOnLoad, this, done, img, tile));
            L.DomEvent.on(img, 'error', L.bind(this._tileOnError, this, done, img, tile));

            if (this.options.crossOrigin) {
                tile.crossOrigin = '';
            }

            //XXX DEBUG
            //tile.innerHTML = coords;

            img.src = this.getTileUrl(coords);

            return tile;
        },

        _tileOnLoad: function (done, img, tile) {
            var src = img.src;
            img.remove();
            tile.style['background-image'] = 'url(' + src + ')';
            tile.style['background-size'] = 'contain';
            tile.style['width'] = '256px';
            tile.style['height'] = '256px';
            // For https://github.com/Leaflet/Leaflet/issues/3332
            if (L.Browser.ielt9) {
                setTimeout(L.bind(done, this, null, tile), 0);
            } else {
                done(null, tile);
            }
        },

        _tileOnError: function (done, img, tile, e) {
            var errorUrl = this.options.errorTileUrl;
            if (errorUrl) {
                tile.src = errorUrl;
            }
            done(e, tile);
        },

    });

    function Artwork(opt) {
        this.options = Object.assign({
            mapid: 'artwork',
            animSpeed: 3, //pixels / sec
            animInterval: 1000, //How often to call anim setInterval
        }, opt);
        this.map = L.map(this.options.mapid, {
            minZoom: minZoom,
            maxZoom: maxZoom,
            rotate: true,
            attributionControl: false,
            dragging: false,
            keyboard: false,
            boxZoom: false,
            zoomControl: false,
            doubleClickZoom: false,
            scrollWheelZoom: false,
            tap: false,
            touchZoom: false,
        });

        this.colorClass = "c1";

        rc = new L.RasterCoords(this.map, imgDimensions);

        this.tiles = new L.BackgroundTileLayer('https://map.timmer.fi/{z}/{x}/{y}.jpg', {
          noWrap: true,
          tileSize:255, //SIC
          attribution: 'Hello' //FIXME
        }).addTo(this.map)
        .on('load', function() {
            setTimeout(() => {
                $('body').addClass('map-loaded');
            }, 1000);
        });
        setTimeout(() => {
            $('body').addClass('map-loaded');
        }, 3000);

        this.velocity = { x: null, y: null};
        this._interval = null;

    }

    Artwork.prototype.setVersion = function(version) {
        var x, y;
        if (version == "random") {
            version = Math.floor(Math.random() * vh.MAXVERSION);
        } else {
            version = parseFloat(version);
        }

        this.version = version;


        version = vh.idToVersion(version); // Scramble version number

        //Top left without image offset
        this.topLeft = {x: ((version % 256) * sidePixels * 0.25), y: (Math.floor(version/256) * sidePixels * 0.25)};

        x = this.topLeft.x + (sidePixels * 0.5); 
        y = this.topLeft.y + (sidePixels * 0.5); 

        //Real, absolute top left
        this.topLeft.x = this.topLeft.x + imgOffset[0];
        this.topLeft.y = this.topLeft.y + imgOffset[1];

        this.colorOffset = (version % 7) + 1;
        this.setColor(this.colorOffset);
        this.setPixels(x, y);
        this.setVersionText();
    };
    
    //Set color as one of 7 predefined css classes
    Artwork.prototype.setVersionText = function() {
        $("#version").text("Version " + vh.versionToString(this.version));
    };

    //Set color as one of 7 predefined css classes
    Artwork.prototype.setColor = function(num) {
        $("#artwork").removeClass(this.colorClass);
        this.colorClass = "c" + num;
        $("#artwork").addClass(this.colorClass);
    };
    
    //Set color as one of 7 predefined css classes
    Artwork.prototype.setColorByIndex = function(num) {
        $("#artwork").removeClass(this.colorClass);
        this.colorClass = "c" + ((num + this.colorOffset) % 7);
        $("#artwork").addClass(this.colorClass);
    };

    Artwork.prototype.setPixels = function(x, y) {
        x = x + imgOffset[0];
        y = y + imgOffset[1];
        this.currentPos = {x: x, y: y};
        this.map.setView(rc.unproject([x, y]), zoomLevel);
    };

    Artwork.prototype.moveBy = function(x, y, done) {
        var mag, v = this.options.animSpeed;
        //Magnitude = euclidean distance
        var px = this.currentPos = rc.project(this.map.getCenter());

        this.stop();

        mag = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        this.targetPos = [px.x + x, px.y + y];
        this.velocity.x = (v / mag) * x;
        this.velocity.y = (v / mag) * y;
        this.animCounter = Math.floor(mag/this.options.animSpeed);
        if (this.animCounter > 0) {
            this._move(done);
        } else if (typeof done === 'function') {
            this._reachedTarget = true;
            done();
        }
    };

    Artwork.prototype.moveTo = function(x, y, done) {
        x = x - this.currentPos.x;
        y = y - this.currentPos.y;
        this.moveBy(x, y, done);
    };

    Artwork.prototype.randomPoint = function() {
        var x = Math.floor(Math.random() * sidePixels);
        var y = Math.floor(Math.random() * sidePixels);
        return {x: x + this.topLeft.x, y: y + this.topLeft.y};
    };


    Artwork.prototype._move = function(done) {
        this.currentPos.x += this.velocity.x;
        this.currentPos.y += this.velocity.y;
        this.map.setView(rc.unproject([this.currentPos.x, this.currentPos.y], 11));
        this.animCounter--;
        if (this.animCounter > 0) {
            this._reachedTarget = false;
            this._timeout = setTimeout(this._move.bind(this), this.options.animInterval);
        } else if (typeof done === 'function') {
            this._reachedTarget = true;
            done();
        }
    };

    Artwork.prototype.stop = function() {
        clearTimeout(this._timeout);
        this._timeout = null;
    };

    Artwork.prototype.continue = function(done) {
        if (!this._reachedTarget) {
            this._move(done);
        }


    };


    return Artwork;

};
