module.exports.stripeHandler = function($) {

    'use strict';

    var action;
    var formdata;

    if (!("StripeCheckout" in window)) {
        return;
    }

    var doPostAction = function() {
          $.ajax({
              type: "POST",
              url: action,
              data: formdata,
              success: function(data) {
                  if (data.errors) {
                      var err = data.errors;
                      if (err.email) {
                          $("#download-email").after("<p class='error'>" + err.email.msg + "</p>");
                      }
                      if (err.amount) {
                          $("#downloadform .currency").after("<p class='error'>" + err.amount.msg + "</p>");
                      }
                      if (err.stripe) {
                          $("#downloadform").append("<p class='error'>Error while processing payment: " + err.stripe.message + "</p>");
                      }
                  } else {
                      $("#download h2").hide();
                      $("#downloadform").removeClass("loading").find("input").blur();
                      $($("#msg_template").html()).appendTo("#download").show().find(".version").text(data.version);
                      $("#download_link").attr("href", data.url);
                      $("#download > *").animate({right: "100%"}, 1000, "swing", function() {
                          $("#downloadform").remove();
                          $("#download_message").css("right", "auto");
                      });
                  }
              }
          });
    };

    var handler = StripeCheckout.configure({
        //FIXME: get token from html
      key: 'pk_live_hqFsMAKPD8gARdk4mR96SCUH',
      image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
      locale: 'auto',
      token: function(token) {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.

          formdata.token = token;
          formdata.email = token.email;

        doPostAction();
      }
    });

   $("#downloadform").submit(function(ev) {
       var $self = $(this);

       $self.addClass("loading");

       $self.find(".error").remove();

       action = $self.attr('action');

       ev.preventDefault();
       formdata = {
           version: $self.find("[name=version]").val(),
           email: $self.find("[name=email]").val(),
       };

       formdata.amount = $self.find('input[name="amount"]').val();
       //Amount is expressed as integer cents
       formdata.amount = Math.round(parseFloat(formdata.amount) * 100);

       formdata.currency = "EUR";

       //Open stripe handler if amount exceeds 50 cents
       //Otherwise just go on to the download area
       if (formdata.amount > 50) {

           handler.open({
               name: 'Johannes Burström',
               description: 'Timmer version ' + formdata.version,
               allowRememberMe: false,
               zipCode: false,
               email: formdata.email,
               currency: formdata.currency,
               amount: formdata.amount
           });

       } else {
           doPostAction();
       }
   });



        // Close Checkout on page navigation:
        window.addEventListener('popstate', function() {
      handler.close();
    });
};
