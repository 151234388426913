const MAXVERSION = 65536;
module.exports.MAXVERSION = MAXVERSION;

var lcg = function(input) {
    return ((input * 245) + 23) % MAXVERSION;
};
var strlen = MAXVERSION.toString().length;

module.exports.nextVersion = function(version) {
    if (typeof(version) !== "undefined") {
        return lcg(version);
    } else {
        return undefined;
    }
};

module.exports.versionToId = function(version) {
    var v = 0;
    var i = 0;
    if (version > MAXVERSION || typeof(version) === "undefined") {
        return undefined;
    } else {
        while (v != version) {
            v = lcg(v);
            i++;
        }
        return i;
    }
};

module.exports.idToVersion = function(id) {
    var v = 0;
    if (typeof(id) === "undefined") {
        return undefined;
    } else {
        for (i = 0; i < id; i++) {
            v = lcg(v);
        }
        return v;
    }
};

module.exports.versionToString = function(version) {
    if (typeof(version) !== "undefined") {
        var str = version.toString();
        while (str.length < strlen) {
            str = "0" + str;
        }

        return str;
    } else {
        return undefined;
    }
};
